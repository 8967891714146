require('@rails/ujs').start()
require('../common/noty.js')

import I18n from 'i18n-js'
window.I18n = I18n

// Keen styles
require('keen/assets/css/pages/login/login-v2.css')

require('keen/assets/plugins/global/plugins.bundle.css')
require('keen/assets/css/style.bundle.css')

// Keen javascripts
require('keen/assets/plugins/global/plugins.bundle.js')
// require('keen/assets/js/scripts.bundle.js')

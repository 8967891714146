window.notyMessage = function(text) {
  return new Noty({
    theme: 'bootstrap-v4',
    type: 'success',
    layout: 'topRight',
    timeout: 3000,
    text: text
  }).show();
};

window.notyWarning = function(text) {
  return new Noty({
    theme: 'bootstrap-v4',
    type: 'warning',
    layout: 'topRight',
    timeout: false,
    text: text
  }).show();
};

window.notyError = function(text) {
  return new Noty({
    theme: 'bootstrap-v4',
    type: 'error',
    layout: 'topRight',
    timeout: false,
    text: text
  }).show();
};
